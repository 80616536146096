@import "../Base.scss";

.profileBody {
  margin-top: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  .profileBody__col1 {
    margin-left: 2rem;
    width: 30%;
    min-width: min-content;
    // margin-right: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    .col1__profile-card1,
    .col1__profile-card2 {
      background-color: white;
      font-weight: $normal;
      min-height: 100%;
      margin: 0 1rem 2rem 1rem;
      padding: 2rem;
      border-radius: $round;
      box-shadow: $shadow;
      display: flex;
      width: 100%;
      flex-direction: column;
    }
  }
}
.job__content {
  background-color: white;
}

.card1__job {
  display: flex;
  align-items: center;
  min-width: max-content;
  margin-bottom: 1rem;
}

.job__label,
.skill__label {
  color: $mainColor;
  min-width: max-content;
  margin-right: 1rem;
}

.job__label {
  margin-right: 2.2rem;
}

.card1__skill {
  display: flex;
  min-width: 50%;
  align-items: flex-start;
  .skill__content {
    img {
      margin: 0 0.1rem;
    }
    .content__none {
      font-size: $fontSs;
      color: $lightGrey;
      font-weight: 400;
    }
  }
  .skill__add-icon {
    color: $lightGrey;
    font-size: $fontMd;
    margin-left: 0.3rem;
    position: relative;
    bottom: 0.1rem;
    cursor: pointer;
    &:hover {
      color: $mainColor;
    }
  }

  .column2__job,
  .column2__skill {
    margin: 0.5rem;
  }
}

input.skill__content {
  position: relative;
  bottom: 0.4rem;
  width: 14rem;
}

.selected-skills {
  display: flex;
  flex-wrap: wrap;
  margin-left: 4rem;
  .selected-el {
    margin: 0.1rem 0.5rem;
    position: relative;
    .selected-x {
      cursor: pointer;
      position: absolute;
      right: -0.8rem;
      bottom: 0;
      color: $grey;
      &:hover {
        color: $darkGrey;
      }
    }
  }
}

.col1__profile-card2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-weight: $normal;
  min-width: max-content;
  height: max-content;
  span,
  a,
  input {
    margin-left: 1rem;
  }
  .email-span,
  .github-span {
    color: $mainColor;
  }
  div {
    margin: 0.2rem 0;
  }
}

.profileBody__col2 {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: $round;
  box-shadow: $shadow;
  padding: 2rem;
  width: 50%;
  // flex-grow: 2;
  margin-right: 2rem;
  margin-left: 2rem;
  span {
    margin-right: 1rem;
  }
  .hr__line {
    color: $lightGrey;
    width: 100%;
    height: 2px;
  }
  .col2__introduction {
    margin: 2rem 0;
  }
  .col2__ongoing-projects,
  .col2__done-projects,
  .col2__registered-projects,
  .col2__interested-projects {
    margin: 1rem 0 0 0;
    span {
      color: $lightGrey;
      font-weight: $normal;
    }
    .col2__subject-span {
      color: $black;
      font-weight: 700;
    }
  }
}

.interested__cards {
  display: flex;
}

.introduction__letters-count {
  position: absolute;
}
input.github-span {
  width: 10rem;
  color: $black !important;
}

.projects__list {
  display: flex;
}

@media (max-width: 800px) {
  .profileBody__col2 {
    width: 80%;
  }
  .profileBody .profileBody__col1 {
    width: 80%;
    margin-right: 2rem;
  }
}
