@import "../Base.scss";

.body-comment {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  // padding: 10px 100px 100px 10px;
  border-radius: $round;
  // border: 1px solid black;
  background: white;
  .comment_main {
    // min-height: 10rem;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    .comment-list {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      text-align: center;
      .comment-id {
        margin: 0.7rem 0.5rem 0.5rem 0.5rem;
        font-weight: $semiBold;
      }
      .comment-content {
        margin: 0 0.5rem;
        padding: 0.5rem 1rem;
        // border: 1px solid $lightestGrey;
        border-radius: 2rem;
        // box-shadow: $shadowLight;
        background-color: $slightGrey;
        text-align: left;
        word-break: break-all;
        line-height: 140%;
        white-space: pre-wrap; /* Since CSS 2.1 */
        white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
        white-space: -pre-wrap; /* Opera 4-6 */
        white-space: -o-pre-wrap; /* Opera 7 */
        word-wrap: break-word; /* Internet Explorer 5.5+ */
      }

      .comment-icons {
        align-items: center;
        font-size: $fontSs;
        color: $lightGrey;
        margin-top: 0.2rem;
        display: flex;
        gap: 0.3rem;
        margin-left: 0.5rem;
        .comment-time {
          font-size: $fontSs;
          color: $lightGrey;
          margin-right: 0.5rem;
          margin-left: 0.5rem;
        }
      }
    }
    .empty-comment {
      margin: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $bodyColor;
      border-radius: $round;
      padding: 2rem;
      color: $lessGrey;
      font-weight: $regular;
    }
  }

  .comment-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  .comment-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    textarea {
      width: 100%;
      padding: 0.7rem 1rem;
      min-height: max-content;
      line-height: 140%;
      // width: max-content;
      // height: max-content;
      resize: none;
      // border-radius: 2rem;
      margin-left: 0.5rem;
    }
  }
  .comment_text {
    margin-left: 0.5rem;
    font-size: $fontMs;
    font-weight: $normal;
  }
  .comment-input {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-top: 1rem;
    border: 2px solid $mainColor;
    border-radius: $round;
    padding: 1rem;
    textarea {
      line-height: 140%;
      padding: 0;
      width: 92%;
      border: none;
      border-radius: 0;
      height: 3rem;
      resize: none;
    }
    button {
      margin-left: 1rem;
      min-width: max-content;
      padding: 0.5rem 1.2rem;
      background-color: $mainColor;
      border: none;
      border-radius: $round;
      color: white;
    }
  }
}

.comment_pagination {
  margin: 2rem 0;
  .pagination {
    display: flex;
    justify-content: center;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  ul.pagination li {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }

  ul.pagination li:first-child {
    border-left: 1px solid #e2e2e2;
    border-radius: 10px 0 0 10px;
    padding-right: 4rem;
  }

  ul.pagination li:last-child {
    border-right: 1px solid #e2e2e2;
    border-radius: 0 10px 10px 0;
    padding-left: 4rem;
  }

  ul.pagination li a {
    text-decoration: none;
    color: #2c2c34;
    font-size: $fontMs;
  }

  ul.pagination li.active a {
    color: #a7bc5b;
  }
}
