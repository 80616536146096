@import "../Base.scss";

.recruit__wrap {
  max-width: 1440px;
  margin: 0 auto;
  .loading-wrap {
    height: 15rem;
  }
}
.recruit {
  margin-bottom: 2rem;
  // padding: 0;
  .recruit__title {
    font-weight: $bold;
    font-size: $fontMl;
    color: $black;
    margin-left: 4rem;
    .innerHtml:hover {
      color: $mainColor;
    }
  }
}
.public {
  margin-bottom: 2rem;
  .public__title {
    font-weight: $bold;
    font-size: $fontMl;
    color: $black;
    margin-left: 4rem;
  }
}
