@import "../Base.scss";

.cadet-bar {
  display: flex;
  background-color: $bodyColor;
  height: 4rem;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  min-width: max-content;
  .cadet-bar-column1 {
    display: flex;
    align-items: center;
  }

  .cadet-bar-column2 {
    display: flex;
    position: relative;
    align-items: center;
    // padding-right: 1rem;
  }

  .b-color {
    font-weight: $bold;
    font-size: $fontMd;
    color: $black;
    margin-left: 2rem;
    cursor: default;
  }

  .a-color {
    font-weight: $bold;
    font-size: $fontMd;
    color: $grey;
    margin-left: 2rem;
    a {
      color: $black;
    }
    &:hover {
      color: $mainColor;
    }
  }
}
