@import "../Base.scss";

.follow__wrapper {
  z-index: 1;
  position: absolute;
  margin-top: 2rem;
  width: 20rem;
  height: 20rem;
  background-color: white;
  border-radius: $round;
  box-shadow: $shadow;
  //   visibility: hidden;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  .follow__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .box-space {
      height: 2rem;
      width: 2rem;
    }
    .follow__subject {
      font-weight: $semiBold;
    }
    .follow__x {
      cursor: pointer;
    }
  }

  .follow__list {
    overflow-y: scroll;
  }
}

.follow__wrapper:after {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-bottom-color: white;
  border-top: 0;
  margin-left: -10px;
  margin-top: -10px;
}

.follow__list {
  padding: 0.5rem;
  height: 100%;
  .loading-wrap {
    height: 100%;
  }
  .follow__list-loader {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $lightGrey;
    font-weight: $normal;
    cursor: pointer;
    .follow__list-more {
      display: flex;
      gap: 0.2rem;
    }
  }
}
.follow__follower {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  .follow__button {
    background-color: $mainColor;
    color: white;
    border: none;
    border-radius: 0.5rem;
    padding: 0.3rem 0.6rem;
    font-size: $fontSs;
    font-weight: $normal;
  }
}

.follow__profile {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.follow__img {
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  margin-right: 1rem;
}

.follow__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  .follow__name {
    font-size: $fontSm;
    color: $black;
    font-weight: $normal;
    margin-bottom: 0.2rem;
  }
  .follow__position {
    font-size: $fontSs;
    color: $grey;
  }
}

.no-follow {
  display: flex;
  justify-content: center;
  height: 90%;
  align-items: center;
  color: $lightGrey;
}

.unfollow__button {
  color: $black;
  background-color: $lightestGrey;
  border: none;
  border-radius: 0.5rem;
  padding: 0.2rem 0.6rem;
  font-size: $fontSs;
  font-weight: $normal;
  .unfollow__icon {
    width: 2rem;
  }
}
