@import "../Base.scss";
.cadet-card {
  margin: 0 1rem;
  min-height: 17rem;
  background-color: white;
  box-shadow: $shadow;
  border-radius: $round;
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1.5rem;
  cursor: pointer;
  max-width: 16rem;
  .cadet__image {
    border-radius: 50%;
    width: 5rem;
    // min-width: max-content;
    // min-height: max-content;
    height: 5rem;
    object-fit: cover;
    margin-bottom: 1rem;
    box-shadow: $shadow;
  }
  &:hover {
    transform: scale(1.1);
    z-index: 1;
    transition: transform 0.2s;
  }
  .cadet__name {
    margin-bottom: 0.5rem;
    font-size: $fontMs;
    font-weight: $semiBold;
  }
  .cadet__work {
    margin-bottom: 1rem;
    color: $mainColor;
    font-size: $fontSm;
    font-weight: $semiBold;
  }
  .cadet__word-wrap {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    .cadet__word {
      position: relative;
      padding: 1rem 0.7rem;
      font-size: $fontSs;
      word-break: break-all;
      text-align: center;
      line-height: 120%;
      background-color: #fff;
      border: 1px solid $lightestGrey;
      border-radius: $round;
      box-shadow: $shadowLight;
    }
    .cadet__word:before {
      border: 9px solid;
      border-color: transparent transparent $lightestGrey transparent;
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
    .cadet__word:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      bottom: calc(100% - 1px);
      right: 50%;
      transform: translateX(50%);
      border: 8.5px solid;
      border-color: transparent transparent white transparent;
    }
  }
  .cadet__no-word {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    font-size: $fontSs;
    color: $lightGrey;
    flex: 1; // 남은 공간 채움
  }
}
