@import "../Base.scss";

.comment-wrap {
  //   background-color: pink;
  //   background-color: $lightestGrey;
  margin-left: 4rem;

  .comment-element {
    display: flex;
    // align-items: center;
    gap: 0.5rem;
    margin: 0.7rem;
    .comment-img {
      margin-top: 1rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      object-fit: cover;
      cursor: pointer;
    }
    .comment-content {
      border-radius: 2rem;
      background-color: $slightGrey;
      line-height: 140%;
      padding: 0.5rem 1rem;
      width: fit-content;
      word-break: break-all;
    }
    input.comment-content {
      background-color: transparent;
      width: auto;
      max-width: 100%;
    }
    .comment-bubble {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      width: 100%;
      .comment-username {
        margin-left: 0.5rem;
        cursor: pointer;
        font-size: $fontSs;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-weight: $normal;
        span:nth-child(2) {
          color: $mainColor;
        }
      }
      .comment-icons {
        font-size: $fontSs;
        color: $lightGrey;
        display: flex;
        gap: 0.5rem;
        margin-left: 0.5rem;
        .comment-time {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .comment__write {
    margin-top: 1rem;
    background-color: $bodyColor;
    border-radius: $round;
    border: 1px solid $lightestGrey;
    width: 100%;
    display: flex;
    height: 2.3rem;
    display: flex;
    align-items: center;
    // padding: 0.5rem;
    .comment__input {
      width: 100%;
      //   height: 1rem;
      //   overflow-y: hidden;
      // padding: 0;
      // height: 1rem;
      resize: none;
      border: none;
      background-color: transparent;
    }
    .comment-send {
      font-size: 1.5rem;
      color: $mainColor;
      cursor: pointer;
      margin-right: 0.5rem;
    }
  }

  .comment__on-edit {
    display: flex;
    gap: 0.5rem;
  }
}
