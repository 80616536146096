@import "../Base.scss";

.positionCard {
  padding: 0.5rem;
  width: 6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div.positionCard__img {
    border: 2px solid $lightestGrey;
    border-radius: 50%;
    margin-bottom: 0.5rem;
  }
  img.positionCard__img {
    border-radius: 50%;
    width: 3.7rem;
    height: 3.7rem;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }
  .positionCard__select {
    background-color: $lightestGrey;
    display: flex;
    height: 1.3rem;
    padding: 0 0.3rem;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    color: $grey;
    width: 5rem;
    position: relative;
    .positionCard__position {
      margin: 0 0.2rem;
      font-size: $fontSs;
      font-weight: $regular;
    }
  }
  .positionCard__x {
    cursor: pointer;
    position: absolute;
    right: -0.5rem;
    bottom: -0.3rem;
    // bottom: 1.5rem;
    // right: 0.6rem;
    color: $lightGrey;
    &:hover {
      color: $grey;
    }
  }
}
