@import "../Base.scss";

.wait__list {
  position: relative;
  .waitlist {
    .waitlist__btn {
      padding: 0.7rem 1.2rem;
      border-radius: 10px;
      border: 1px solid #e5e5e5;
      text-align: center;
      cursor: pointer;
      margin-left: 1rem;
      min-width: max-content;
    }
    .waitlist__count {
      position: absolute;
      z-index: 1;
      top: -0.5rem;
      right: -0.2rem;
      width: 1.2rem;
      height: 1.2rem;
      font-weight: $normal;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      color: white;
      font-size: $fontSs;
      padding: 0.2rem;
      text-align: center;
      background-color: $pointRed;
    }
    .waitlist__count_zero {
      position: absolute;
      z-index: 1;
      top: -0.5rem;
      right: -0.2rem;
      width: 1.2rem;
      height: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: $normal;
      border-radius: 50%;
      color: white;
      font-size: $fontSs;
      padding: 0.3rem;
      text-align: center;
      background-color: $lightGrey;
    }
  }
  .waitlist__wrap {
    position: absolute;
    background-color: white;
    // border-bottom-left-radius: 0.3rem;
    padding: 0.7rem;
    border-radius: $round;
    box-shadow: $shadow;
    top: 2.9rem;
    right: -13rem;
    // left: 1rem;
    width: 20rem;
    z-index: 3;
    overflow: hidden;
    .waitlist__header {
      padding: 0.5rem;
      font-size: $fontSm;
      font-weight: $normal;
      border-bottom: 1px solid $lightestGrey;
    }
    .waitlist__body {
      max-height: 19rem;
      overflow-y: scroll;
      width: 100%;
      padding: 0;
      .body__card {
        border-bottom: 1px solid $lightestGrey;
        // padding: 0 0.5rem;
        align-items: center;
        .card__row1 {
          padding: 0.5rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: $fontSm;
          justify-content: space-between;
          .card__img {
            img {
              border-radius: 50%;
              width: 2rem;
              height: 2rem;
              object-fit: cover;
            }
          }
          .card__icon {
            display: flex;
            flex-direction: row;
            svg {
              margin: 0.1rem;
            }
          }
        }
      }
      .notiText__none {
        background-color: $bodyColor;
        color: $grey;
        font-size: $fontSs;
        margin: 0.7rem 0 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: $round;
      }
    }
  }
}
