@import "../Base.scss";

.project-bar {
  display: flex;
  background-color: $bodyColor;
  // height: 4rem;
  align-items: center;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 2rem;
  margin-top: 1rem;
  // flex-wrap: wrap;
  margin-bottom: 1rem;
}

.project-bar-column1 {
  display: flex;
  align-items: center;
  .project-btn,
  .project-btn-mobile {
    min-width: max-content;
  }
}

.project-bar-column2 {
  display: flex;
  align-items: center;
  min-width: max-content;
  // padding-right: 1rem;
}

.a-color {
  color: $grey;
}

.b-color {
  color: $black;
  cursor: default;
  &:hover {
    color: $black;
  }
}

.project-btn {
  font-weight: $bold;
  font-size: $fontMd;
  margin-left: 2rem;
  .innerHtml:hover {
    color: $mainColor;
  }
}

.project-btn-mobile {
  display: none;
  font-weight: $bold;
  font-size: $fontMs;
  align-items: center;
  margin-left: 2rem;
  .innerHtml:hover {
    color: $mainColor;
  }
}

.create-project {
  padding: 0.5rem 1rem;
  border-radius: $round;
  border: none;
  background-color: $mainColor;
  color: white;
  font-weight: $regular;
  margin: 0 1rem;
  cursor: pointer;
  &:hover {
    color: white;
  }
  .create-text-mobile {
    display: none;
  }
}

.filter_wrap {
  position: relative;
}

@media (max-width: 640px) {
  .project-btn {
    display: none;
  }

  .project-btn-mobile {
    display: flex;
    margin-left: 1rem;
  }
  .create-text-mobile {
    display: inline-block;
  }
}
// @media (max-width: 480px) {
//   .project-bar {
//     gap: 1rem;
//   }
//   .project-bar-column1 {
//     flex-direction: column;
//     gap: 1rem;
//   }
//   .allproject__wrap {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
// }
