@import "../Base.scss";

.unauth__wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  gap: 2rem;
  .Nav__user__login {
    color: white;
    margin: 0;
    padding: 0.7rem 1.5rem;
    font-size: $fontMs;
    border-radius: $round;
    a:hover {
      color: white;
    }
  }
}

.unauth__header {
  font-size: $fontXXl;
  font-weight: $bold;
  //   color: $mainColor;
}

.unauth__body {
  font-weight: $regular;
}
