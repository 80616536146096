@import "../Base.scss";

.lounge-post {
  background-color: white;
  width: 100%;
  //   height: 14rem;
  border-radius: $round;
  margin-bottom: 1.5rem;
  box-shadow: $shadow;
  padding: 1.5rem 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.lounge-post__row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .lounge-post__profile {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.profile__img {
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  object-fit: cover;
  // margin-bottom: 0.5rem;
  margin-right: 1rem;
}
.profile__name {
  font-size: $fontMs;
}

.lounge-post__time {
  color: $lightGrey;
}

.lounge-post__space {
  // background-color: beige;
  min-height: 2rem;
  line-height: 140%;
  margin: 2rem 4rem;
  word-break: break-all;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  overflow-y: hidden;
}

.lounge-post__edit-space {
  min-height: 8rem;
  line-height: 140%;
  padding: 1rem;
  margin: 1rem 4rem;
  // resize: none;
  max-height: 40rem;
}

.lounge-post__bottom {
  bottom: 2rem;
  display: flex;
  justify-content: space-between;
  .bottom__open-comments {
    margin-left: 4rem;
    color: $lightGrey;
    border: none;
    padding: 0;
    background-color: white;
    .bottom__comment-icon {
      position: relative;
      bottom: 0.05rem;
    }
  }
  .bottom__comment-icon {
    margin-left: 0.3rem;
  }
}
.bottom__info {
  display: flex;
  gap: 0.8rem;
  color: $grey;
  align-items: center;
  cursor: pointer;
  .bottom__edit,
  .bottom__delete,
  .bottom__on-edit {
    color: $lightGrey;
  }
  .bottom__on-edit {
    display: flex;
    gap: 0.8rem;
  }
  .bottom__like {
    margin-left: 1rem;
    &:hover {
      color: $pointRed;
    }
  }
  .like-color {
    color: $pointRed;
  }
  .bottom__like-icon {
    margin-right: 0.2rem;
    position: relative;
    bottom: 0.2rem;
    font-size: $fontMs;
  }
  .bottom__like-icon-fill {
    margin-right: 0.2rem;
    position: relative;
    bottom: 0.2rem;
    font-size: $fontMs;
    color: $pointRed;
  }
}

.post__edited {
  color: $lightGrey;
  font-size: $fontSs;
}
