@import "./Base.scss";
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 400;
  src: url("../fonts/AppleSDGothicNeoR.ttf");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 500;
  src: url("../fonts/AppleSDGothicNeoM.ttf");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 600;
  src: url("../fonts/AppleSDGothicNeoSB.ttf");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 700;
  src: url("../fonts/AppleSDGothicNeoB.ttf");
}
@font-face {
  font-family: "AppleSDGothicNeo";
  font-weight: 800;
  src: url("../fonts/AppleSDGothicNeoEB.ttf");
}
body {
  font-family: "Nunito Sans", "AppleSDGothicNeo", sans-serif;
  // margin: 0.5rem 1rem;
  background-color: $bodyColor;
  color: $darkGrey;
}
@media (max-width: 75em) {
  html {
    font-size: 12px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: $mainColor;
  }
  cursor: pointer;
}

::-webkit-scrollbar {
  // background-color: none;
  width: 0.7rem;
  right: 0.5rem;
}
::-webkit-scrollbar-thumb {
  // background: $mainColor;
  border-radius: $round;
  background: $lightestGrey;
}
::-webkit-scrollbar-thumb:hover {
  background: $lightGrey;
}
// ::-webkit-scrollbar-track-piece:end {
//   background: transparent;
//   margin-bottom: 1rem;
// }

// ::-webkit-scrollbar-track-piece:start {
//   background: transparent;
//   margin-top: 1rem;
// }

input,
select,
textarea {
  color: $darkGrey;
  border: 2px solid $mainColor;
  padding: 0.2rem 0.7rem;
  border-radius: $round;
  &:focus {
    outline: none;
  }

  /* safari 등 브라우저 기본 CSS 방지 */
  // -webkit-appearance: none;
  // -moz-appearance: none;
  // appearance: none;
  // -webkit-border-radius: 0.7rem; /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  // -moz-border-radius: 0.7rem; /* Firefox 1-3.6 */
  // border-radius: 0.7rem; /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
}

::placeholder {
  color: $lightGrey;
}

/* 드래그 방지 */
// html {
//   -ms-user-select: none;
//   -moz-user-select: -moz-none;
//   -webkit-user-select: none;
//   -khtml-user-select: none;
//   user-select: none;
// }
