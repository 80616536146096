@import "../Base.scss";

.project_content {
  // min-height: 15rem;
  margin: 1.5rem 1rem 1rem 0rem;
  .project_content_row1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    .project_address {
      margin-left: 0.5rem;
      font-size: $fontMs;
      text-align: center;
      font-weight: $normal;
    }
  }
  .md_viewer {
    // margin-left: 2rem;

    margin: 2rem 2rem 1rem 2rem;
  }

  // 레퍼런스 링크 부분
  .link_list {
    display: flex;
    flex-direction: column;
    font-size: $fontMs;
    font-weight: $normal;
    gap: 0.5rem;
    margin-top: 2rem;
    .link_title {
      display: flex;
      gap: 0.5rem;
      margin-top: 1rem;
      margin-bottom: 0.5rem;
    }
    .link_wrap {
      margin-left: 1.5rem;
      font-size: $fontSm;
      font-weight: $regular;
      display: flex;
      align-items: center;
      gap: 1rem;
      color: $grey;

      .link_favicon {
        width: 2.5rem;
        height: 2.5rem;
        box-shadow: $shadowLight;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $lightestGrey;
        img {
          width: 1.7rem;
          height: 1.7rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
        }
      }

      .link_url {
        border-bottom: 1px solid $lightestGrey;
        padding: 0.2rem 0;
        max-width: 60%;
        white-space: nowrap;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  /* toast UI */
  .toastui-editor-contents {
    font-size: 1rem;
    font: inherit;
    overflow-wrap: break-word;
  }
  .toastui-editor-contents h1 {
    border-bottom: 2px solid $lightestGrey !important;
  }
  .toastui-editor-contents h2 {
    border-bottom: 1px solid $lightestGrey !important;
  }
}
