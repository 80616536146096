@import "./Base.scss";

.loading-wrap {
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  svg {
    max-width: 5rem;
    max-height: 5rem;
  }
}
