@import "../Base.scss";

.dashboard {
  // background-color: $mainColor;
  //부모에 상관없이 width채우는 법
  width: 100%;
  // margin-left: calc(-50vw + 50%);
  // border-radius: $round;
  margin-bottom: 3rem;
  // background-image: url(https://cdn.pixabay.com/photo/2018/04/04/14/34/design-3289964_960_720.png);
  // background-size: cover;
  background: linear-gradient(
      0deg,
      rgba(167, 188, 91, 0.95),
      rgba(167, 188, 91, 0.95)
    ),
    // url(https://cdn.pixabay.com/photo/2018/04/04/14/34/design-3289964_960_720.png);
    url(../../dashboardImg.png);
  // box-shadow:inset 0 0 0 2000px rgba(167, 188 ,91, 0.95);
  box-shadow: 0rem 0.5rem 0.5rem 0rem rgba(0, 0, 0, 0.1);
  .dashboard__wrap {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2.5rem;
  .row1__user {
    display: flex;
    align-items: center;
    color: white;
    .user__image {
      font-size: $fontXXl;
      margin-right: 1rem;
    }
    .IntraImage {
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      object-fit: cover;
      margin-right: 1rem;
    }
    .user__name {
      font-size: $fontLg;
      font-weight: $bold;
      margin-right: 0.5rem;
    }
  }
  .row1__button {
    position: relative;
    left: 0rem;
    bottom: 1rem;
    color: $lightestGrey;
    font-size: $fontMd;
    padding: 0.3rem 0.7rem;
    border-radius: 0.7rem;
    background-color: rgba(0, 0, 0, 0.53);
  }
  .icon__link {
    color: $lightestGrey;
    &:hover {
      color: $mainColor;
    }
  }
}

.row2 {
  display: flex;
  justify-content: center;
  padding: 0 2.5rem;
  height: 14rem;
  .row2__box1-wrap {
    background-color: rgba(0, 0, 0, 0.53);
    border-radius: $round;
    padding: 3rem;
    height: 100%;
  }
  .row2__box1 {
    height: 100%;
    display: flex;
    flex-grow: 1;
    overflow-y: auto;
    &::-webkit-scrollbar {
      background-color: transparent;
      -ms-overflow-style: none;
      // scrollbar-width: 0;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    .box1__column1 {
      color: $mainColor;
      font-size: 1.3rem;
      font-weight: $semiBold;
      margin-right: 1.5rem;
      min-width: max-content;

      .column1__job {
        margin-bottom: 2rem;
      }
    }
    .box1__column2 {
      left: 10rem;
      color: $lightestGrey;
      font-size: 1.3rem;
      font-weight: $normal;
      .column2__job {
        margin-bottom: 2rem;
      }
      .column2__skill {
        display: flex;
        max-width: 15rem;
        flex-wrap: wrap;
        img {
          display: inline-block;
          font-size: 5px;
          margin: 0.5rem 0.25rem;
          margin-top: 0rem;
        }
        .not-skills {
          margin-left: 1rem;
        }
      }
    }
  }
  .row2__box2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.53);
    padding: 2rem;
    border-radius: $round;
    color: $lightestGrey;
    font-size: 1.3rem;
    font-weight: $normal;
    margin-left: 1.7rem;
    position: relative;
    width: 8rem;
    &:hover {
      .cardbutton {
        visibility: visible;
      }
    }
    .box2__title {
      position: absolute;
      left: 1.5rem;
      top: 1.5rem;
    }
    .box2__slides {
      position: relative;
      top: 1rem;
    }
  }
  .row2__box3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-grow: 1;
    background-color: rgba(0, 0, 0, 0.53);
    margin-left: 1.7rem;
    padding: 2rem;
    border-radius: $round;
    color: $lightestGrey;
    font-size: 1.3rem;
    font-weight: $normal;
    width: 8rem;
    &:hover {
      .cardbutton {
        visibility: visible;
      }
    }
    .box3__title {
      position: absolute;
      left: 1.5rem;
      top: 1.5rem;
    }
    .box3__slides {
      position: relative;
      top: 1rem;
    }
  }
}

.row3 {
  display: flex;
  justify-content: center;
  align-items: center;
  .row3__reportbox {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $round;
    width: 35rem;
    height: 3rem;
    box-shadow: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.15);
    position: relative;
    top: 1.5rem;
    padding: 0.6rem;
    .dashboard__project__link1,
    .dashboard__project__link2 {
      color: $pointRed;
    }
    .dashboard__project__link3,
    .dashboard__project__link4 {
      color: $mainColor;
    }
    .dashboard__project__link1,
    .dashboard__project__link3 {
      font-size: $fontMd;
    }
  }

  .reportbox__report {
    font-weight: $semiBold;
  }
}

.row2 .row2__box1:hover {
  &::-webkit-scrollbar-thumb {
    background-color: $grey;
    border-radius: $round;
  }
}

//16px = 1rem
@media (max-width: 880px) {
  .row2 .row2__box3 {
    display: none;
  }
}
@media (max-width: 608px) {
  .row2 .row2__box1 {
    // flex-grow: 1;
    .box1__column1 {
      margin: 0 2rem;
    }
    .box1__column2 {
      .column2__skill {
        max-width: 100%;
      }
    }
  }
  .row2 .row2__box2 {
    display: none;
  }
  .row2__box1-wrap {
    width: 100%;
  }
}

// 화면이 뭉게지기 시작한 시점
@media (max-width: 448px) {
  .dashboard {
    min-width: 28rem;
  }
}
