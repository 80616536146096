@import "../../Base.scss";

.inChat {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .inChat__header {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    .back {
      cursor: pointer;
      &:hover {
        color: $mainColor;
      }
    }
    .name {
      font-weight: $bold;
      font-size: 1.3rem;
      margin: 0 auto;
      position: relative;
      left: -1.5rem;
      span {
        margin-right: 1rem;
      }
    }
    .nav__resize-up {
      // position: absolute;
      right: 4rem;
      cursor: pointer;
      margin-right: 1rem;
      &:hover {
        color: $mainColor;
      }
    }
    .nav__resize-down {
      margin-right: 1rem;
      // position: absolute;
      right: 4rem;
      cursor: pointer;
      &:hover {
        color: $mainColor;
      }
    }
    .btn {
      padding: 0;
      margin-right: 0.5rem;
      position: absolute;
      right: 1.5rem;
      box-shadow: none;
      .inChat__menu-icon {
        font-size: $fontMd;
        &:hover {
          color: $mainColor;
        }
      }
    }
    .close {
      position: absolute;
      right: 0;
      cursor: pointer;
      &:hover {
        color: $mainColor;
      }
    }
  }

  .inChat__body {
    width: 100%;
    height: 80%;
    overflow-y: scroll;
    overflow-x: hidden;
    .empty-msg {
      padding: 0.5rem 0;
    }
    .empty-msg-bottom {
      padding: 0.5rem 0;
    }
  }

  .inChat__input-small {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 2px solid $mainColor;
    border-radius: $round;
    background-color: white;
    input {
      width: 100%;
      border: none;
    }
    .input__send {
      min-width: max-content;
      color: $mainColor;
      cursor: pointer;
      font-weight: $bold;
    }
  }
}
