@import "../Base.scss";

.DashCards {
  padding: 1rem;
  &:hover {
    transform: scale(1.1);
  }
  transition: transform 0.2s;
  .DashCards__image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    border-radius: $round;
    width: 12rem;
    height: 8rem;
    border: none;
    img {
      border-radius: $round;
      object-fit: cover;
      width: 100%;
      height: 100%;
      filter: brightness(50%);
      -webkit-filter: brightness(50%);
    }
    .DashCards__title {
      position: absolute;
      width: 100%;
      line-height: 120%;
      color: $lightestGrey;
      bottom: 1rem;
      padding: 0 0.5rem;
      font-size: $fontSs;
      font-weight: $normal;
      text-align: center;
      word-wrap: break-word;
      white-space: pre-wrap; /* CSS3*/
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-all; /* Internet Explorer 5.5+ */
    }
  }
}
