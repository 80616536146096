@import "../Base.scss";

.recruitCadet-wrap {
  margin: 0 auto;
  max-width: 1440px;
  .recruitCadet-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    // grid-template-columns: repeat(auto-fill, minmax(200px, max-content));
    align-content: start;
  }
  .noCadet {
    display: flex;
    justify-content: center;
    padding: 6rem;
    font-weight: $normal;
    opacity: 0.5;
    border-radius: $round;
    background-color: $lightestGrey;
    margin: 2rem;
  }
}

@media (min-width: 1000px) {
  .recruitCadet-wrap .recruitCadet-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
