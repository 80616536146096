@import "../Base.scss";

.project-edit__wrapper {
  padding: 1rem;
  overflow: auto;
  max-width: 1400px;
  background-color: white;
  border-radius: $round;
  box-shadow: $shadow;
  margin: 1rem auto 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  input,
  textarea {
    border: 2px solid $lightestGrey;
    padding: 0.5rem 1rem;
  }
  select {
    border: 2px solid $lightestGrey;
  }
  button {
    padding: 0.5rem 1rem;
    border-radius: $round;
    // border: 2px solid $lightGrey;
    background-color: white;
    color: $grey;
    min-width: max-content;
  }
  .project-edit__thumbnail {
    border-radius: $round;
    display: flex;
    align-items: flex-end;
    min-width: max-content;
    position: relative;
    width: 18rem;
    height: 12rem;
    cursor: pointer;
    .loading-wrap {
      position: absolute;
      z-index: 3;
      width: 100%;
      height: 100%;
      div {
        height: 15% !important;
        width: 15% !important;
      }
    }
    .project-edit__img {
      border-radius: $round;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 18rem;
      height: 12rem;
      background-color: $lightestGrey;
      transition: opacity 400ms ease 0ms;
    }
    .project-edit__img-hover {
      z-index: 1;
      position: absolute;
      width: 18rem;
      height: 12rem;
      border-radius: $round;
      background-color: rgba(0, 0, 0, 0.3);
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
      transition: opacity 0.1s ease-in;

      .img-hover__add {
        border: 2px solid $lightestGrey;
        width: max-content;
        padding: 0.5rem 1rem;
        border-radius: $round;
        color: $lightestGrey;
      }
    }
  }

  .project-edit__form {
    display: flex;
    // flex-wrap: wrap-reverse;
    // justify-content: center;
    label {
      margin: 2rem 0 1rem 0;
      font-weight: $semiBold;
    }
  }

  .project-edit__body {
    display: flex;
    flex-direction: row;
  }

  .project-edit__buttons {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    button {
      color: white;
      border: none;
      margin: 0 0.5rem;
      // font-weight: $regular;
    }
    .project-edit__delete {
      // background-color: $pointRed;
      border: 1px solid $lightestGrey;
      color: $darkGrey;
    }
    .project-edit__save {
      background-color: $mainColor;
    }
  }
  .project-edit__asterisk {
    color: $pointRed;
    margin-left: 0.2rem;
  }
}

.project-edit__left,
.project-edit__right {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 1rem;
}
.project-edit__left {
  width: 43%;
  // background-color: pink;
  position: relative;
  .project-edit__position-label {
    display: flex;
    align-items: baseline;
  }
  .project-edit__add-position {
    margin: 0 2rem;
    background-color: $bodyColor;
    color: $grey;
  }
  .project-edit__position {
    display: flex;
    flex-wrap: wrap;
  }

  .project-edit__skill-label {
    display: flex;
    align-items: center;
    .project-edit__add-skill {
      margin-left: 2rem;
      background-color: $bodyColor;
    }
  }

  .project-edit__skill {
    .project-edit__add-skill {
      width: 14.5rem;
      padding: 0.3rem 0.7rem;
    }
    .add-skill__icon {
      color: $lightGrey;
      margin-left: 0.5rem;
      font-size: 1.5rem;
      cursor: pointer;
      &:hover {
        color: $mainColor;
      }
    }
  }
  .project-edit__selected-skill {
    display: flex;
    flex-wrap: wrap;
    .selected-el {
      margin: 0.1rem 0.5rem;
      position: relative;
      .selected-x {
        position: absolute;
        right: -0.8rem;
        bottom: 0;
        color: $grey;
        cursor: pointer;
        &:hover {
          color: $darkGrey;
        }
      }
    }
  }
  .project-edit__period {
    min-width: max-content;
  }

  .project-edit__reference {
    input {
      width: 92%;
      margin-right: 0.5rem;
      background-color: $bodyColor;
      padding: 0.3rem 1rem;
    }
    .reference-icon {
      color: $lightGrey;
      font-size: 1.5rem;
      cursor: pointer;
      &:hover {
        color: $mainColor;
      }
    }
    .selected-reference {
      display: flex;
      align-items: center;
      margin-top: 0.5rem;
      .reference-url {
        width: 92%;
        margin-right: 0.5rem;
        border: 2px solid $lightestGrey;
        padding: 0.5rem 1rem;
        border-radius: $round;
        white-space: nowrap;
        // position: relative;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
          display: none;
        }
      }
      .reference-x {
        font-size: 1.5rem;
        color: $lightGrey;
        cursor: pointer;
        &:hover {
          color: $pointRed;
        }
      }
    }
  }
}
.project-edit__right {
  box-sizing: border-box;
  width: 60%;
  // background-color: beige;
  .project-edit__introduction {
    box-sizing: border-box;
  }
  .project-edit__name {
    width: 26.5rem;
  }
}

/* Toast-UI editor */
.toastui-editor-defaultUI {
  border: 2px solid $lightestGrey !important;
  border-radius: $round !important;
  overflow-x: hidden;
  button,
  input {
    border-radius: $round !important;
  }
  span {
    border-radius: $round;
  }
  .toastui-editor-popup-add-image .toastui-editor-file-name {
    border-radius: $round;
  }
  .placeholder {
    background-color: transparent;
    opacity: 0.6;
    cursor: auto;
  }
  input.tui-colorpicker-palette-button {
    padding: 0;
  }
  .toastui-editor-popup-body input[type="text"].tui-colorpicker-palette-hex {
    height: 30px;
    width: calc(100% - 10px);
  }
  .toastui-editor-md-code {
    border-radius: 0;
  }
  .toastui-editor-defaultUI-toolbar {
    border-top-right-radius: $round !important;
    // overflow-x: hidden;
    padding: 0 0.8rem;
  }
  .toastui-editor-md-tab-container {
    border-top-left-radius: $round !important;
  }
  .toastui-editor-mode-switch {
    border-bottom-left-radius: $round;
    border-bottom-right-radius: $round;
  }
  .toastui-editor-popup {
    border-radius: $round;
  }
  .toastui-editor-md-tab-container {
    .tab-item {
      border-top-left-radius: $round !important;
      border-top-right-radius: $round !important;
    }
  }
  .toastui-editor-mode-switch {
    .tab-item {
      border-bottom-left-radius: $round !important;
      border-bottom-right-radius: $round !important;
    }
  }
  .toastui-editor-md-preview .toastui-editor-contents h1 {
    border-bottom: 2px solid $lightestGrey !important;
  }
  .toastui-editor-md-preview .toastui-editor-contents h2 {
    border-bottom: 1px solid $lightestGrey !important;
  }
  .toastui-editor-contents p {
    font-size: 1rem;
  }
  .ProseMirror {
    font-size: inherit;
  }
}
.project-edit__start-date,
.project-edit__end-date {
  width: 11rem;
  padding: 0.2rem 0.5rem !important;
  margin: 0 0.5rem;
}

@media (max-width: 950px) {
  .project-edit__form {
    padding: 1rem;
    flex-direction: column-reverse;
    justify-content: flex-start;
    justify-content: center;
    .project-edit__left,
    .project-edit__right {
      width: 95%;
    }
  }
}
