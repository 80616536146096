@import "../Base.scss";

.ProjectDetail__wrap {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1440px;
}

// .projectdetail-header {
//   display: flex;
//   // height: 100px;
//   margin: 10px;
//   padding: 100px;
//   border-radius: $round;
//   background: cornflowerblue;
// }

.projectdetail-body {
  margin: 1px 10px 10px 10px;
  display: flex;
  border-radius: $round;
  flex-direction: row;
  justify-content: space-between;
}

.projectdetail-body__row1 {
  display: flex;
  flex-direction: column;
  width: 21%;
  min-width: 15rem;
  margin-right: 1rem;
  .apply-state {
    display: flex;
    padding: 100px;
    justify-content: center;
    border-radius: $round;
    // margin: 10px;
    // border: 1px solid black;
    background: $mainColor;
  }
  .project-info {
    // justify-content: left;
    padding: 10px 50px 50px 10px;
    margin: 10px;
    box-shadow: $shadow;
    border-radius: $round;
    // border: 1px solid black;
    background: white;
  }
  .project-apply {
    padding: 3rem;
  }
  .project-cancle_btn {
    border: 1px solid $lightestGrey;
    box-shadow: $shadowLight;
    border-radius: $round;
    display: flex;
    justify-content: center;
    width: 5rem;
    min-width: max-content;
    margin: 1rem auto;
    padding: 0.7rem 1.2rem;
    color: #595959;
  }
}

.projectdetail-body_row2 {
  display: flex;
  width: 78%;
  flex-direction: column;
  margin: 0.5rem 0;
  padding: 2.5rem;
  // border: 1px solid black;
  background-color: white;
  border-radius: $round;
  box-shadow: $shadow;
  .project_memberlist {
    display: flex;
    flex-direction: row;
    // // align-items: center;
    // justify-content: center;
  }
  .body-member {
    padding: 10px 100px 100px 10px;
    border-radius: $round;
    border: 1px solid black;
    background: white;
  }
  .body-content {
    // padding-bottom: 1rem;
    border-radius: $round;
    // border: 1px solid black;
    background: white;
  }
  hr {
    height: 1px;
    color: $lightGrey;
  }
}

@media (max-width: 700px) {
  .projectdetail-body {
    // margin: 1px 10px 10px 10px;
    display: flex;
    border-radius: $round;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .projectdetail-body__row1 {
    width: 100%;
    margin-right: 0rem;
  }
  .projectdetail-body_row2 {
    width: 100%;
  }
}
