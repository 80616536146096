@import "../../Base.scss";

@keyframes bounce {
  from {
    transform: translateY(0rem);
  }
  to {
    transform: translateY(-0.5rem);
  }
}

.chat {
  position: -webkit-sticky;
  position: fixed;
  z-index: 5;
  //   background-color: pink;
  color: $black;
  bottom: 0.5rem;
  right: 1rem;

  &:hover {
    animation: bounce 0.5s infinite alternate;
  }

  visibility: visible;
  .chat__icon {
    cursor: pointer;
  }
}

.chat__noti {
  position: absolute;
  right: -0.2rem;
  top: -0.2rem;
  span {
    display: block;
    // align-items: baseline;
    // width: 1.2rem;
    color: white;
    background-color: $pointRed;
    padding: 0.2rem 0.4rem;
    border-radius: 50%;

    // user-drag: none;
    // -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
}

.chatLog {
  visibility: hidden;
  // transition: visibility 2000ms;
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;
  // background-color: rgba(255, 255, 255, 0.7);
  // backdrop-filter: blur(10px);
  // -webkit-backdrop-filter: blur(10px);
  background-color: $bodyColor;
  width: 22rem;
  height: 40rem;
  border-radius: $round;
  box-shadow: 0px 0px 2rem 1rem rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 3;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .chatLog__nav {
    font-weight: $bold;
    font-size: $fontMs;
    display: flex;
    justify-content: space-between;
    vertical-align: bottom;
    margin-bottom: 0.5rem;
    width: 100%;
    .nav__resize-up,
    .nav__resize-down {
      cursor: pointer;
      :hover {
        color: $mainColor;
      }
    }

    .nav__left-icons {
      display: flex;
      align-items: center;
    }

    .nav__empty-div {
      width: 2rem;
    }

    .nav__chat-title {
      display: flex;
      align-items: center;
    }
    .nav__icons {
      display: flex;
      .nav__plus {
        cursor: pointer;
        margin-right: 0.3rem;
        :hover {
          color: $mainColor;
        }
      }
      .nav__x {
        cursor: pointer;
        :hover {
          color: $mainColor;
        }
      }
    }
  }
  .chatLog__addConv {
    background-color: $lightestGrey;
    font-weight: $regular;
    padding: 0.5rem;
    font-size: $fontSm;
    border-radius: 0.7rem;
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }
    .addConv__input {
      background-color: $lightestGrey;
      border: none;
      width: auto;
      padding-left: 0.5rem;
      width: 100%;
      &:focus {
        outline: none;
      }
      &::placeholder {
        font-size: $fontSs;
      }
    }
    .create-icon {
      opacity: 0.5;
      &:hover {
        opacity: 1;
        color: $mainColor;
      }
    }
  }
  .chatLog__addUser-big {
    width: 100%;
    background-color: white;
    max-height: 20rem;
    overflow-y: scroll;
    border-radius: $round;
    .addUser__card {
      display: flex;
      align-items: center;
      margin: 0.5rem;
      padding-left: 1rem;
      border-radius: $round;
      &:hover {
        background-color: $lightestGrey;
      }
      img {
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        object-fit: cover;
        margin-right: 0.5rem;
      }
      div {
        font-size: $fontMs;
      }
    }
  }
  .chatLog__addUser {
    width: 20rem;
    // position: absolute;
    background-color: white;
    max-height: 15rem;
    overflow-y: scroll;
    border-radius: $round;

    .addUser__card {
      display: flex;
      align-items: center;
      margin: 0.5rem;
      padding-left: 1rem;
      border-radius: $round;
      &:hover {
        background-color: $lightestGrey;
      }
      img {
        border-radius: 50%;
        width: 1.5rem;
        height: 1.5rem;
        object-fit: cover;
        margin-right: 0.5rem;
      }
    }
  }
  .chatLog__body {
    width: 100%;
    overflow-y: auto;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
    .chatLog__body-empty {
      width: 100%;
      height: 100%;
      padding: 1rem;
      border-radius: $round;
      display: flex;
      font-weight: $normal;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      .chatLog__body-empty-info {
        font-weight: 400;
        color: $lightGrey;
        margin-top: 1rem;
        font-size: $fontSs;
        line-height: 130%;
      }
    }
  }
}
