@import "../Base.scss";

.modal__background {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 30;
}

.modal__wrap {
  width: 25rem;
  min-height: 10rem;
  background-color: white;
  border-radius: $round;
  z-index: 30;
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -5rem 0 0 -12.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  line-height: normal;
  // box-shadow: $shadowAll;
  // font-weight: $regular;
  .modal__body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5rem 2rem;
  }
  hr {
    margin: 0;
    width: 100%;
    height: 2px;
    color: $lightGrey;
    opacity: 0.25;
  }
  .modal__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    // background-color: $mainColor;
    // border-bottom-left-radius: $round;
    // border-bottom-right-radius: $round;
    div {
      width: 100%;
      padding: 1rem;
      cursor: pointer;
      // color: white;
    }
    div:nth-child(2) {
      border-left: 2px solid #f0f0f0;
      //   background-color: $pointRed;
      //   border-bottom-right-radius: $round;
    }
  }
}
