@import "../Base.scss";

.unsplash__wrapper {
  background-color: white;
  box-shadow: $shadow;
  border-radius: $round;
  width: 25rem;
  height: 35rem;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 25;
  margin: -17.5rem 0 0 -12.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: $shadowAll;
  .unsplash__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    .box-space {
      width: 2rem;
    }
    .unsplash__subject {
      font-weight: $normal;
    }
    .unsplash__x {
      cursor: pointer;
    }
  }
  hr {
    margin: 0;
    color: $lightGrey;
    height: 2px;
  }
  .unsplash__tab {
    display: flex;
    padding: 0.5rem;
    font-weight: $normal;
    div {
      margin: 0 1rem;
      color: $lightGrey;
      cursor: pointer;
    }
    .presets-icon,
    .search-icon,
    .upload-icon {
      margin-right: 0.3rem;
    }
    .tab__upload {
      color: $darkGrey;
    }
  }
  .unsplash__body {
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .unsplash__body-info {
      color: $grey;
      margin: 1rem;
      line-height: normal;
    }
  }

  .unsplash__button {
    background-color: $lightestGrey;
    border: none;
    cursor: default;
    width: 5rem;
  }
  .unsplash__button-active {
    background-color: $mainColor;
    border: none;
    color: white;
    width: 5rem;
  }
}
