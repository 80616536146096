@import "../Base.scss";

.filter {
  z-index: 3;
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 0rem;
  top: 3rem;
  padding: 1rem;
  width: 20rem;
  background-color: white;
  box-shadow: $shadow;
  border-radius: $round;
  .filter_header {
    padding: 0.5rem;
    font-size: $fontSm;
    font-weight: $normal;
    border-bottom: 1px solid $lightestGrey;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  .skill_filter {
    display: flex;
    flex-direction: column;
    .project-edit__skill {
      display: flex;
      flex-direction: row;
      align-items: center;
      .add-skill__icon {
        color: $lightGrey;
        margin-left: 0.5rem;
        font-size: 1.8rem;
        cursor: pointer;
        &:hover {
          color: $mainColor;
        }
      }
    }
    .skill__tags {
      margin: 0.5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .skill__wrap {
        position: relative;
        .skill_x {
          position: absolute;
          background-color: white;
          border-radius: 1rem;
          right: -0.5rem;
          top: -0.5rem;
          color: $grey;
          cursor: pointer;
        }
      }
    }
  }
  .position_filter {
    display: flex;
    flex-direction: column;
    #job_select {
      margin-bottom: 1rem;
    }
    .position__tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      position: relative;
      // margin-top: 0.2rem;
      .position__tag {
        padding: 0.3rem 0.7rem;
        margin: 0.5rem;
        background-color: $lightestGrey;
        border-radius: 0.5rem;
        font-size: $fontSs;
      }
      .tag_wrap {
        position: relative;
        .position_x {
          position: absolute;
          right: 0rem;
          // bottom: 0;
          // left: 3.6rem;
          top: 0.2rem;
          color: $grey;
          cursor: pointer;
        }
      }
    }
  }
  .level_filter {
    display: flex;
    flex-direction: column;
    // align-items: center;
    // justify-content: center;
  }

  .filter_btn__wrap {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 0.5rem;
  }
  .filter_btn {
    margin-top: 1rem;
    background-color: white;
    padding: 0.7rem 1.2rem;
    border-radius: $round;
    border: 1px solid $lightestGrey;
    color: $darkGrey;
  }
  .project-edit__add-skill {
    width: 100%;
    padding: 0.3rem 0.7rem;
  }
}

input[type="range"] {
  height: 38px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type="range"]:focus {
  outline: none;
}
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000;
  // background: #3071a9;
  background: $lightestGrey;
  border-radius: 5px;
  border: 1px solid #000000;
}
input[type="range"]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 30p;
  width: 15px;
  border-radius: 5px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -10px;
}
input[type="range"]:focus::-webkit-slider-runnable-track {
  background: $lightestGrey;
}
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  box-shadow: 1px 1px 1px #000000;
  background: $lightestGrey;
  // background: #3071a9;
  border-radius: 5px;
  border: 1px solid #000000;
}
input[type="range"]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 30px;
  width: 15px;
  border-radius: 5px;
  background: $mainColor;
  // background: #ffffff;
  cursor: pointer;
}
input[type="range"]::-ms-track {
  width: 100%;
  height: 12px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  // background: $lightestGrey;

  // color: transparent;
}
input[type="range"]::-ms-fill-lower {
  background: #3071a9;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
input[type="range"]::-ms-fill-upper {
  background: $lightestGrey;
  // background: #3071a9;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #000000;
}
input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #000000;
  border: 1px solid #000000;
  height: 30px;
  width: 15px;
  border-radius: 5px;
  // background: #ffffff;
  background: $mainColor;
  cursor: pointer;
}
input[type="range"]:focus::-ms-fill-lower {
  background: #3071a9;
}
input[type="range"]:focus::-ms-fill-upper {
  background: $lightestGrey;
}
