@import "../Base.scss";

.popover {
  box-shadow: $shadow;
  border-radius: $round;
  border-color: white;
}
.popover-body {
  &:hover {
    color: $mainColor;
    cursor: pointer;
  }
  padding-bottom: 0rem;
  font-weight: 600;
  color: $darkGrey;
}

.popover__wrap {
  position: relative;
  padding-bottom: 1rem;
}

.popover-arrow::before {
  border-bottom-color: white !important;
}
