@import "../Base.scss";

.cadet {
  box-sizing: border-box;
  width: 60%;
  .cadet__title {
    font-weight: $bold;
    font-size: $fontMl;
    color: $black;
    padding-left: 4rem;
    cursor: pointer;
  }
  .cadet__list {
    margin-top: 2rem;
    padding: 0 2rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    // justify-items: stretch;
    // justify-content: stretch;

    // .list__row1,
    // .list__row2 {
    //   justify-content: center;
    // }
  }
  .noCadet {
    display: flex;
    justify-content: center;
    padding: 15rem 0;
    font-weight: $normal;
    opacity: 0.5;
    border-radius: $round;
    background-color: $lightestGrey;
    margin: 2rem;
    // height: 100%;
  }
}

@media (max-width: 1000px) {
  .cadet {
    width: 100%;
    margin-bottom: 2rem;
    .cadet__list {
      grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    }
  }
}

@media (max-width: 450px) {
  .cadet {
    .cadet__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
