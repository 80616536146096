@import "../../Base.scss";

.Nav {
  display: flex;
  background-color: $bodyColor;
  height: 4rem;
  align-items: center;
  justify-content: space-between;
}

.Nav-column1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Nav-column2 {
  display: flex;
  align-items: center;
}

.Nav__title {
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .title-text {
    letter-spacing: -0.1rem;
    color: $mainColor;
    font-size: $fontXl;
    font-weight: 900;
  }
  .title-icon {
    position: relative;
    bottom: -0.18rem;
    font-size: 3.2rem;
    color: $black;
  }
}
.Nav__input {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .input-icon {
    font-size: 1.8rem;
    position: absolute;
    bottom: -1.2rem;
    left: 0.1rem;
  }
  .input__res {
    box-shadow: $shadow;
    position: absolute;
    border-bottom-left-radius: $round;
    border-bottom-right-radius: $round;
    padding: 0.5rem;
    left: 2.4rem;
    top: 1.5rem;
    z-index: 3;
    width: 18rem;
    background-color: white;
    a {
      color: $black;
    }
    .res__user {
      display: flex;
      flex-direction: column;
      padding: 0.3rem 0.3rem;
      .res__title1 {
        font-size: $fontSs;
        font-weight: $regular;
        padding-bottom: 0.3rem;
        color: $grey;
        border-bottom: 1px solid $lightestGrey;
      }
      .res__userlist {
        max-height: 20rem;
        overflow-y: auto;
      }
      .res__userCard {
        display: flex;
        align-items: center;
        padding-top: 0.3rem;
        cursor: pointer;
        border-radius: $round;
        &:hover {
          background-color: $bodyColor;
        }
        img {
          width: 2rem;
          height: 2rem;
          border-radius: $round;
          object-fit: cover;
        }
        .name {
          margin-left: 0.5rem;
        }
      }
    }
    .res__project {
      display: flex;
      flex-direction: column;
      padding: 0.3rem 0.3rem;
      margin-top: 0.5rem;
      .res__title2 {
        font-size: $fontSs;
        font-weight: $regular;
        padding-bottom: 0.3rem;
        color: $grey;
        border-bottom: 1px solid $lightestGrey;
      }
      .res__projectlist {
        max-height: 20rem;
        overflow-y: auto;
      }
      .res__projectCard {
        display: flex;
        border-radius: $round;
        align-items: center;
        padding-top: 0.3rem;
        cursor: pointer;
        &:hover {
          background-color: $bodyColor;
        }
        img {
          width: 2rem;
          height: 2rem;
          border-radius: $round;
          object-fit: cover;
        }
        .title {
          margin-left: 0.5rem;
        }
      }
    }
  }
  input {
    font-weight: $normal;
    border-radius: 0;
    position: absolute;
    padding: 0.4rem;
    bottom: -1.3rem;
    left: 2.5rem;
    font-size: $fontSm;
    width: 18rem;
    border: none;
    background-color: $bodyColor;
    &:focus {
      outline: none;
      border-bottom: 2px solid $mainColor;
    }
  }
  ::placeholder {
    color: $lightestGrey;
    font-weight: $normal;
  }
}

.Nav__project,
.Nav__cadet,
.Nav__lounge {
  position: relative;
  bottom: -0.3rem;
  margin-right: 2rem;
  font-size: $fontMd;
  font-weight: $bold;
}

.nav-color {
  color: $black;
}

.Nav__lounge {
  margin-right: 5rem;
}
.Nav__notification {
  position: relative;
  font-size: $fontLg;
  margin-right: 1rem;
  .icon {
    position: relative;
    top: 0.2rem;
    &:hover {
      color: $mainColor;
      cursor: pointer;
    }
  }
  .haveNotification {
    z-index: 1;
    position: absolute;
    font-size: $fontSs;
    padding: 0.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.2rem;
    min-width: 1.2rem;
    border-radius: 50%;
    font-weight: $normal;
    background-color: $pointRed;
    color: white;
    left: 0.8rem;
    top: -0.2rem;
  }
  .notNotification {
    padding: 0.2rem;
    z-index: 1;
    position: absolute;
    font-size: $fontSs;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
    font-weight: $normal;
    background-color: $lightGrey;
    color: white;
    left: 0.8rem;
    top: -0.2rem;
  }
  .notiText__wrap {
    position: absolute;
    background-color: white;
    // border-bottom-left-radius: 0.3rem;
    padding: 0.7rem;
    border-radius: $round;
    box-shadow: $shadow;
    top: 3.3rem;
    right: -0.5rem;
    width: 20rem;
    z-index: 3;
    overflow: hidden;
    .notiText__header {
      padding: 0.5rem;
      margin-right: 0.7rem;
      font-size: $fontSm;
      font-weight: $normal;
      border-bottom: 1px solid $lightestGrey;
    }
    .notiText__body {
      max-height: 10rem;
      overflow-y: scroll;
      .card-wrap {
        border-radius: $round;
        &:hover {
          background-color: $bodyColor;
        }
        .type {
          border-bottom: 1px solid $lightestGrey;
          padding: 0.3rem 0.5rem;
          font-size: $fontSm;
          cursor: pointer;
          line-height: normal;
          .row1 {
            padding: 0;
            display: flex;

            .title {
              color: $mainColor;
              font-weight: $semiBold;
              font-size: $fontSm;
            }
            .date {
              opacity: 0.5;
              font-size: $fontSs;
            }
          }
          .userName {
            font-weight: $semiBold;
          }
          .projectName {
            font-weight: $semiBold;
          }
        }
      }
      .notiText__none {
        background-color: $bodyColor;
        color: $grey;
        font-size: $fontSs;
        margin: 0.7rem 0 0 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        border-radius: $round;
      }
    }
  }
}
.Nav__user__login {
  position: relative;
  margin-right: 2rem;
  min-width: max-content;
  bottom: -0.2rem;
  background-color: $mainColor;
  border-radius: 0.7rem;
  padding: 0.4rem 1.1rem;
  border: none;
  font-weight: 700;
  // transition: box-shadow 0.9s;

  overflow: hidden;
  &:after {
    content: "";
    height: 8rem;
    left: -3rem;
    opacity: 0.2;
    position: absolute;
    top: -3rem;
    background: #fff;
    -webkit-transform: rotate(35deg);
    transform: rotate(35deg);
    -webkit-transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.55s cubic-bezier(0.19, 1, 0.22, 1);
    width: 2rem;
  }
  &:hover:after {
    // transform: scale(1.05);
    // background-color: #66aaff;
    // box-shadow: 10rem 0 0 0  inset;
    left: 120%;
  }
  &:active {
    transform: scale(0.95);
  }
  .login__link {
    color: white;
    font-size: $fontMs;
  }
}

.Nav__user {
  position: relative;
  display: flex;
  align-items: center;
  .name {
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $fontMd;
      margin-right: 0.3rem;
      color: $darkGrey;
      font-weight: $bold;
      box-shadow: none;
      &:hover {
        color: $mainColor;
      }
    }
  }
  .name__list {
    z-index: 3;
    position: absolute;
    right: 2rem;
    top: 3.5rem;
    width: 6.5rem;
    // padding: 1rem;
    background-color: white;
    padding: 1rem 0;
    border-bottom-left-radius: $round;
    border-bottom-right-radius: $round;
    .name__list__wrap {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .list__profile {
        &:hover {
          color: $mainColor;
        }
        color: $darkGrey;
        font-size: $fontSm;
        font-weight: $bold;
        margin-bottom: 1rem;
        border-bottom: 1px solid $lightestGrey;
        padding-bottom: 1rem;
      }
      .list__signOut {
        &:hover {
          color: $mainColor;
        }
        cursor: pointer;
        color: $darkGrey;
        font-size: $fontSm;
        font-weight: $bold;
      }
    }
  }
  .image {
    position: relative;
    right: -1rem;
    font-size: $fontMd;
    margin-right: 1rem;
    .IntraImage {
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      object-fit: cover;
    }
  }
}
.Nav__menu {
  display: none;
}

// @media (max-width: 78rem) {
//   .Nav {
//     display: flex;
//   }
//   // .Nav__input {
//   //   display: none;
//   // }
// }

@media (max-width: 900px) {
  .Nav__project,
  .Nav__cadet,
  .Nav__lounge,
  .Nav__input {
    display: none;
  }
  .Nav__user__login {
    margin-right: 0;
  }
  .Nav__menu {
    display: flex;
    .btn {
      box-shadow: none;
      .Nav__menu-icon {
        font-size: $fontLg;
        &:hover {
          color: $mainColor;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .Nav__notification,
  .Nav__user .image,
  .Nav__input {
    display: none;
  }
}

@media (max-width: $iphone5Width) {
  .Nav__input {
    display: none;
  }
}
