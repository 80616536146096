@import "../Base.scss";

.project_info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem;
  margin: 0.5rem 0;
  box-shadow: $shadow;
  border-radius: $round;
  background: white;
  min-height: 10rem;
  hr {
    height: 1px;
    color: $lightGrey;
  }

  .info_icon {
    display: flex;
    flex-direction: row;
    justify-content: right;
    // align-items: right;
    margin-bottom: 1.3rem;
    color: $grey;
    svg {
      cursor: pointer;
      transition: width 300ms ease;
      // transform: translate(-50%, -50%);
    }
    svg:hover {
      transform: scale(1.1);
    }
  }

  .info_date {
    font-weight: $semiBold;
    margin-bottom: 0.5rem;
    min-width: max-content;
  }

  .info_skill {
    font-weight: $semiBold;
    margin-bottom: 0.5rem;
    min-width: max-content;
  }
  .skill {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    img {
      margin: 5px 5px 0px 0px;
    }
  }
  .info_bottom {
    display: flex;
    flex-direction: row;
    margin-top: 5rem;
    font-size: $fontSs;
  }

  .info_not-defined {
    font-size: $fontSs;
    color: $grey;
  }
}
.project-cancle_btn {
  cursor: pointer;
}
