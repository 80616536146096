@import "../../Base.scss";

.chatCard-other {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0.2rem 0;
  .chatCard__column1 {
    margin: 0 0.5rem;
    img {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      object-fit: cover;
    }
    .empty {
      width: 2.5rem;
    }
  }
  .chatCard__column2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .column1-small__name {
      font-weight: $bold;
      font-size: $fontSs;
      padding: 0.2rem 0.4rem;
      .chat-time {
        font-size: 0.6rem;
        margin-left: 0.5rem;
        opacity: 0.5;
      }
    }
    .chat-message-small {
      margin-right: 0.5rem;
      font-size: $fontSm;
      padding: 0.5rem 0.8rem;
      box-shadow: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.05);
      border-radius: $round;
      background-color: white;
      line-height: 120%;
      word-break: break-all;
    }
  }
}
.chatCard-me {
  margin: 0.2rem 0;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row-reverse;
  .chatCard__column1 {
    margin: 0 0.5rem;
    img {
      border-radius: 50%;
      width: 2.5rem;
      height: 2.5rem;
      object-fit: cover;
    }
    .empty {
      width: 2.5rem;
    }
  }
  .chatCard__column2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .column1-small__name {
      font-weight: $bold;
      font-size: $fontSs;
      padding: 0.2rem 0.4rem;
      .chat-time {
        font-size: 0.6rem;
        margin-right: 0.5rem;
        opacity: 0.5;
      }
    }
    .chat-message-small {
      font-size: $fontSm;
      padding: 0.5rem 0.8rem;
      box-shadow: 0rem 0.3rem 0.3rem 0rem rgba(0, 0, 0, 0.05);
      border-radius: $round;
      background-color: white;
      line-height: 120%;
      word-break: break-all;
    }
  }
}

.chatCard-noti {
  display: flex;
  justify-content: center;
  width: 95%;
  margin-top: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: $round;
  .chat-noti {
    margin: 0 auto;
    padding: 0.5rem;
    font-weight: $normal;
    color: $grey;
    font-size: $fontSs;
  }
}
