@import "../Base.scss";
.lounge {
  position: relative;
  width: 40%;
  padding: 0 2rem;
  // padding-right: 2rem;
  .lounge__title {
    font-weight: $bold;
    font-size: $fontMl;
    color: $black;
    cursor: pointer;
    margin-left: 2rem;
  }
  .lounge__no-lounge {
    width: 100%;
    background-color: $lightestGrey;
    border-radius: $round;
    opacity: 0.5;
    padding: 15rem 0;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    font-weight: $normal;
    align-items: center;
    // color: $darkGrey;
  }
  .lounge__list {
    padding-top: 2rem;
    .lounge__card {
      background-color: white;
      padding: 2rem;
      width: 100%;
      box-shadow: $shadow;
      border-radius: $round;
      margin-bottom: 2rem;
      position: relative;
      cursor: pointer;
      &:hover {
        transform: scale(1.05);
        z-index: 1;
        transition: transform 0.2s;
      }
      .card__row1 {
        display: flex;
        align-items: center;
        .row1__image {
          border-radius: 50%;
          width: 2.5rem;
          height: 2.5rem;
          object-fit: cover;
          // margin-bottom: 0.5rem;
          margin-right: 1rem;
        }
        .row1__right {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          .row1__name {
            position: relative;
            font-weight: $semiBold;
          }
          .row1__time {
            font-size: $fontSs;
            color: $grey;
          }
        }
      }
      .card__row2 {
        padding-left: 3.5rem;
        margin-bottom: 1rem;
        line-height: 140%;
        word-break: break-all;
      }
      .card__row3 {
        position: absolute;
        font-size: $fontSs;
        right: 1rem;
        bottom: 1rem;
        .row3-icon {
          font-size: $fontSm;
          position: relative;
          bottom: 0.1rem;
          right: 0.1rem;
        }
        .like-num {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .lounge {
    // margin-top: 4rem;
    width: 100%;
  }
}
