@import "../Base.scss";

.lounge-write {
  background-color: white;
  box-shadow: $shadow;
  width: 100%;
  height: 15rem;
  border-radius: $round;
  margin-bottom: 2rem;
  padding: 1.5rem 2rem;

  display: flex;
  flex-direction: column;
  .lounge-write__profile {
    display: flex;
    align-items: center;
    .profile__img {
      border-radius: 50%;
      width: 3rem;
      height: 3rem;
      object-fit: cover;
      // margin-bottom: 0.5rem;
      margin-right: 1rem;
    }
    .profile__icon {
      width: 3rem;
      height: 3rem;
      margin-right: 1rem;
      color: $darkGrey;
      opacity: 0.85;
    }
    .profile__name {
      font-size: $fontMs;
    }
  }
}
.lounge-write__space {
  //   display: flex;
  //   justify-content: center;
  // padding: 0 1rem 1rem 4rem;
  margin: 0 1rem 1rem 4rem;
  // position: relative;
  display: flex;
  align-items: flex-end;
  background-color: $bodyColor;
  border-radius: $round;
  border: 1px solid $lightestGrey;
  // position: relative;
  // background-color: pink;
  button {
    margin: 0.5rem;
  }
}

.lounge-write__box {
  background-color: transparent;
  border-radius: $round;
  border: none;
  height: 8rem;
  width: 90%;
}
textarea.lounge-write__box {
  resize: none;
  // min-height: 8rem;
  padding: 1rem;
  line-height: 140%;
}

.lounge-write__submit {
  // position: relative;
  // bottom: -2rem;
  background-color: $mainColor;
  color: white;
  border: none;
  border-radius: 0.7rem;
  z-index: 1;
  margin-left: 1.5rem;
  padding: 0.5rem 1.5rem;
  min-width: 2rem;
  height: 1rem;
  box-sizing: content-box;
}
