@import "../Base.scss";

.cards {
  position: relative;
  width: 20rem;
  margin-right: 1.7rem;
  background-color: white;
  // background-color: pink;
  box-shadow: $shadow;
  border-radius: $round;
  padding: 1rem;
  &:hover {
    transform: scale(1.1);
    z-index: 1;
  }
  transition: transform 0.2s;
  // min-width: 22rem;

  // .card__new-tag {
  //   position: absolute;
  //   right: 0.5rem;
  //   top: 2rem;
  //   background-color: $pointRed;
  //   z-index: 1;
  //   padding: 0.3rem 0.5rem;
  //   color: white;
  //   font-weight: $semiBold;
  //   border-radius: 0.4rem;
  //   font-size: $fontSs;
  // }
  .card__new-tag {
    position: absolute;
    z-index: 1;
    left: 0.9rem;
    top: 2rem;
    height: 1.5rem;
    width: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $pointRed;
    color: white;
    font-size: $fontSs;
    font-weight: $normal;
    box-shadow: -1px 2px 5px rgba(0, 0, 0, 0.3);
    // border-top-right-radius: 0.3125rem;
    // border-bottom-right-radius: 0.3125rem;
  }
  .card__new-tag:before,
  .card__new-tag:after {
    content: "";
    position: absolute;
    box-sizing: content-box !important;
  }
  .card__new-tag:before {
    width: 0.45rem;
    height: 100%;
    top: 0;
    left: -0.3rem;
    padding: 0 0 0.45rem;
    background: inherit;
    border-radius: 0.3125rem 0 0 0.3125rem;
  }
  .card__new-tag:after {
    top: 0;
    right: -0.69rem;
    border-top: 0.75rem solid $pointRed;
    border-bottom: 0.75rem solid $pointRed;
    border-right: 0.75rem solid transparent;
  }
  .card__image {
    position: relative;
    border-radius: $round;
    width: 18rem;
    height: 11.5rem;
    background-color: #808080;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
    overflow: hidden;

    img {
      position: absolute;
      border-radius: $round;
      object-fit: cover; // 이미지 안찌그러지면서 꽉차게
      width: 100%;
      height: 100%;
      filter: brightness(50%);
    }
    // img.card_img__small {
    // filter: blur(5px) brightness(50%);
    // transform: scale(1.1);
    // transition: all 0ms ease 400ms;
    // -webkit-transition: -webkit-transform 2000ms ease-in-out 0ms;
    // }
    .image__title {
      width: 18rem;
      position: absolute;
      line-height: 120%;
      color: $lightestGrey;
      font-weight: $normal;
      bottom: 2rem;
      text-align: center;
      padding: 0 1rem;
      word-wrap: break-word;
      white-space: pre-wrap; /* CSS3*/
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-all; /* Internet Explorer 5.5+ */
      font-size: 1.2rem;
    }
  }
  .card__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0.4rem;
    .info__left {
      display: flex;
      .info__interest,
      .info__chat {
        margin-right: 0.5rem;
      }
    }
    .info__right {
      .member__icon {
        font-size: $fontMd;
        position: relative;
        // bottom: 0.rem;
        // z-index: 2;
      }
    }
  }
}
